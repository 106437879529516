/*!
 * Start Bootstrap - Coming Soon v4.0.0-beta.2 (https://startbootstrap.com/template-overviews/coming-soon)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-coming-soon/blob/master/LICENSE)
 */


@import "https://fonts.googleapis.com/css?family=Merriweather:300,300i,400,400i,700,700i,900,900i";
@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i";

html {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Merriweather';
  font-weight: 700;
}

body {
  height: 100%;
  position: relative;
  font-family: 'Source Sans Pro';
  font-weight: 300;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.75;
  z-index: -1;
}

.masthead {
  position: relative;
  overflow: hidden;
  padding-bottom: 3rem;
}

.masthead .masthead-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  transform: skewY(4deg);
  transform-origin: bottom right;
}

.masthead .masthead-content h1 {
  font-size: 2.5rem;
  font-family: sans-serif;
}

.masthead .masthead-content p {
  font-size: 1.2rem;
}

.masthead .masthead-content p strong {
  font-weight: 700;
}

.masthead .masthead-content .input-group-newsletter input {
  font-size: 1rem;
  padding: 1rem;
}

.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 1rem;
}

@media (min-width: 768px) {
  .masthead {
    height: 100%;
    width: 40.5rem;
    padding-bottom: 0;
  }
  .masthead .masthead-bg {
    transform: skewX(-8deg);
    transform-origin: top right;
  }
  .masthead .masthead-content {
    padding-left: 3rem;
    padding-right: 10rem;
  }
  .masthead .masthead-content h1 {
    font-size: 2.5rem;
    font-family: sans-serif;
  }
  .masthead .masthead-content p {
    font-size: 1.3rem;
  }
}

.social-icons ul {
  margin-top: 2rem;
}

.social-icons ul > li {
  margin-left: 1rem;
  margin-right: 1rem;
  display: inline-block;
}

.social-icons ul > li > a {
  display: block;
  color: white;
  background-color: rgba(0, 46, 102, 0.8);
  border-radius: 100%;
  font-size: 2rem;
  line-height: 4rem;
  height: 4rem;
  width: 4rem;
}

@media (min-width: 768px) {
  .social-icons {
    position: absolute;
    right: 2.5rem;
    bottom: 2rem;
  }
  .social-icons ul {
    margin-top: 0;
  }
  .social-icons ul > li {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .social-icons ul > li:last-child {
    margin-bottom: 0;
  }
  .social-icons ul > li > a {
    transition: all 0.2s ease-in-out;
    font-size: 2rem;
    line-height: 4rem;
    height: 4rem;
    width: 4rem;
  }
  .social-icons ul > li > a:hover {
    background-color: #002E66;
  }
}

.btn-secondary {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}

.btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover {
  background-color: #ababab!important;
  border-color: #ababab!important;
}

.input {
  font-weight: 300 !important;
}
